const myString = {
    "about_us_txt": "About School",

    "th_about_us_detail": "สถาบันสอนภาษาอีซี่เวย์ ก่อตั้งขึ้นในปี 2544 ด้วยความคิดที่ว่าทุกคนเกิดมาไม่ได้เป็นเพียงประชากรของประเทศใดประเทศหนึ่ง แต่เป็นส่วนหนึ่งของประชาคมโลก และการเข้าถึงวัฒนธรรมอื่นนั้น สามารถเริ่มต้นด้วยการพัฒนาความสามารถทางด้านภาษา การมีแนวทางที่ง่ายเปรียบเสมือนเส้นทางลัดในการเข้าใจแก่นแท้ของภาษาไวยิ่งขึ้น",
    "about_us_detail": "Easy Way Language School is established with the idea that the population of each country is not only a part in that oneself but the part of the world community. So, to get into other cultural, this can be started with the knowledge of languages.",

    "th_founder_txt": "ประวัติก่อตั้ง",
    "founder_txt": "History",

    "th_founding_detail": "ร่วมก่อตั้งสถาบันภาษา Easy Way ตั้งแต่ปี 2544 โดยเริ่มจากการให้บริการจัดการเรียนการสอนโดยครูต่างประเทศให้แก่โรงเรียนในจังหวัดสงขลา และเริ่มเปิดโรงเรียนสอนพิเศษสำหรับผู้ที่อยากเรียนรู้ หรือพัฒนาภาษาอังกฤษ ในทุกช่วงวัย หลังจากนั้นไม่นาน ได้เปิดสอนพิเศษเพิ่มเติมในวิชาทั่ว ๆ ไปสำหรับเด็กนักเรียนผู้ที่ต้องการพัฒนาความรู้เพิ่มเติม",
    "founding_detail": "Easy Way Language School is the first established in 2001. Starting by providing foreigner teachers to schools inside Songkhla province and surrounding provinces. Not long after, English special class is opened for interested learner or people who want to study english in all ages. Then, other subjects is also provided for students who need improvement in their knowledge.",
}
export default myString