import React, { useState } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import myString from '../res/ourCourse.js';
import bgImg from '../res/images/temp1.jpg';
import course1 from '../res/images/course1.gif';
import course2 from '../res/images/course2.gif';
import course3 from '../res/images/course3.gif';
import course4 from '../res/images/course4.gif';

function Ourcourses() {
    const [isC1Shown, setIsC1Shown] = useState(false);
    const [isC2Shown, setIsC2Shown] = useState(false);
    const [isC3Shown, setIsC3Shown] = useState(false);
    const [isC4Shown, setIsC4Shown] = useState(false);
    const styles = {
        bgImage: {
          position: 'absolute',
          zIndex: -1,
          width: '100%',
          height: '100%',
          resizeMode: 'contain'
        },
        oddContent: {
            backgroundColor: '#ffffff',
            padding: "100px"
        },
        evenContent: {
            padding: "100px"
        },
        coursesImg: {
            position: 'center',
            width: '100%',
            height: '100%',
            display: 'block'
        },
        im2txt: {
            width: '100%',
            height: '100%',
            display: 'block',
            paddingLeft: '10px',
            paddingTop: '10px',
            paddingRight: '10px',
        },
        coursesTxt: {
            textAlign: "center",
            fontSize: '24px',
            fontWeight: 'bold'
        },
        coursesBlk: {
            backgroundColor: '#e9e9e9',
            marginLeft: '25px',
            marginRight: '25px',
            width: '100%'
        },
        heroText2: {
          position: 'relative',
          textAlign: 'left',
          color: '#000000',
          fontSize: '45px'
        },
        heroText3: {
          position: 'relative',
          textAlign: 'left',
          color: '#6c6c6c',
          fontSize: '16px'
        },
        topicDevider: {
          marginTop: 50,
        },
        head: {
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#b90000'
        },
      };
    return (
        <>
            {/* Content 1 */}
            <img style={styles.bgImage} src={bgImg} alt="bg" />
            <div style={styles.evenContent}>
                <Container style={styles.topicDevider} fluid='md'>
                    <Row>
                        <Col>
                            <h1 style={styles.heroText2}>{myString.course_txt}</h1>
                            <h3 style={styles.heroText3}>{myString.course_desc}</h3>
                        </Col>
                        <Col>

                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Content 2 */}
            <div style={styles.oddContent}>
                <Container style={styles.topicDevider}>
                    <h2 style={styles.head}>{myString.our_course_txt}</h2>
                    <div style={styles.topicDevider} />
                    <Row>
                        <Col>
                            <div style={styles.coursesBlk} onMouseEnter={() => setIsC1Shown(true)} onMouseLeave={() => setIsC1Shown(false)}>
                                {!isC1Shown && (
                                    <img style={styles.coursesImg} src={course1} alt="course1"/>
                                )}
                                {isC1Shown && (
                                    <div>
                                        <img style={styles.coursesImg} src={course1} alt="course1"/>
                                        <span style={styles.im2txt}>{myString.course_1_detail}</span>
                                    </div>
                                )}
                                <p style={styles.coursesTxt}>{myString.course_1_head}</p>
                            </div>
                        </Col>
                        <Col>
                            <div style={styles.coursesBlk} onMouseEnter={() => setIsC2Shown(true)} onMouseLeave={() => setIsC2Shown(false)}>
                                {!isC2Shown && (
                                    <img style={styles.coursesImg} src={course2} alt="course2" />
                                )}
                                {isC2Shown && (
                                    <div>
                                        <img style={styles.coursesImg} src={course2} alt="course2" />
                                        <span style={styles.im2txt}>{myString.course_2_detail}</span>
                                    </div>
                                )}
                                <p style={styles.coursesTxt}>{myString.course_2_head}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={styles.coursesBlk} onMouseEnter={() => setIsC3Shown(true)} onMouseLeave={() => setIsC3Shown(false)}>
                                {!isC3Shown && (
                                    <img style={styles.coursesImg} src={course3} alt="course3" />
                                )}
                                {isC3Shown && (
                                    <div>
                                        <img style={styles.coursesImg} src={course3} alt="course3" />
                                        <span style={styles.im2txt}>{myString.course_3_detail}</span>
                                    </div>
                                )}
                                <p style={styles.coursesTxt}>{myString.course_3_head}</p>
                            </div>
                        </Col>
                        <Col>
                            <div style={styles.coursesBlk} onMouseEnter={() => setIsC4Shown(true)} onMouseLeave={() => setIsC4Shown(false)}>
                                {!isC4Shown && (
                                    <img style={styles.coursesImg} src={course4} alt="course4" />
                                )}
                                {isC4Shown && (
                                    <div>
                                        <img style={styles.coursesImg} src={course4} alt="course4" />
                                        <span style={styles.im2txt}>{myString.course_4_detail}</span>
                                    </div>
                                )}
                                <p style={styles.coursesTxt}>{myString.course_4_head}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default Ourcourses;