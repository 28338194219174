import React from 'react';
import myString from './res/homeString.js';
import { Row, Container, Col } from 'react-bootstrap';
import bgImg from './res/images/temp1.jpg';
import heroImg from './res/images/home_over.png';
import history from './utility/history';

function App() {
  const styles = {
    bgImage: {
      position: 'absolute',
      zIndex: -1,
      width: '100%',
      height: '100%',
      resizeMode: 'contain'
    },
    oddContent: {
      backgroundColor: '#ffffff',
      padding: "100px"
    },
    heroImage: {
      paddingTop: '150px',
      paddingBottom: '150px',
      position: 'absolute',
      height: '100%'
    },
    heroText1: {
      paddingTop: '150px',
      position: 'relative',
      textAlign: 'left',
      // color: '#d77474',
      color: '#b90000',
      fontSize: '14px',
      fontWeight: "bold"
      
    },
    heroText2: {
      position: 'relative',
      textAlign: 'left',
      color: '#000000',
      fontSize: '45px'
    },
    heroText3: {
      position: 'relative',
      textAlign: 'left',
      color: '#6c6c6c',
      fontSize: '16px'
    },
    buttonHero: {
      marginLeft:'auto',
      marginRight:'auto',
      borderStyle: 'none',
      borderColor: '#000000',
      backgroundColor: '#ac1313',
      padding: '20px 40px',
      color: '#ffffff',
      lineHeight: '22px'
    },
    buttonHeroContainer: {
      marginTop: '20px',
      paddingBottom: '150px',
    },
    activitiesImages: {
      width: 256,
      height: 256,
      marginRight: 20
    },
    head: {
      textAlign: 'center',
      fontWeight: 'bold',
      color: '#b90000'
    },
    buttonContainer:{
      display: "flex"
    },
    buttonMore: {
      marginLeft:'auto',
      marginRight:'auto'
    },
    topicDevider: {
      marginTop: 50,
    },
    contentDevider: {
      marginTop: 20,
    }
  };
  return (
    <>
      {/* Content 1 */}
      <img style={styles.bgImage} src={bgImg} alt="bgImg" />
      <Container style={styles.topicDevider}>
        <Row>
          <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
            <h3 style={styles.heroText1}>{myString.heroText1}</h3>
            <h1 style={styles.heroText2}>{myString.heroText2}</h1>
            <h3 style={styles.heroText3}>{myString.heroText3}</h3>
            <div style={styles.buttonHeroContainer}><button style={styles.buttonHero} onClick={() => history.push('/contact-us')}>{myString.buttonHero}</button></div>
          </Col>
          <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
            {/* <img style={styles.heroImage} src={heroImg} alt="decoImg"/> */}
          </Col>
        </Row>
      </Container>

      {/* Content 2 */}
      <div style={styles.oddContent}>
        <Container style={styles.topicDevider}>
          <h2 style={styles.head}>{myString.content_Head}</h2>
          <div style={styles.topicDevider} />
          <Row>
            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }}>
              <h3>{myString.content_detail1_head}</h3>
              <p>{myString.content_detail1}</p>
            </Col>
            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 2 }}>
              <h3>{myString.content_detail2_head}</h3>
              <p>{myString.content_detail2}</p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default App;
