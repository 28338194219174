import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import bgImg from '../res/images/temp1.jpg';
import mapImg from '../res/images/map.png'
import myString from '../res/contactUs.js';

function ContactUs() {
    const styles = {
        bgImage: {
            position: 'absolute',
            zIndex: -1,
            width: '100%',
            height: '100%',
            resizeMode: 'contain'
          },
          oddContent: {
            backgroundColor: '#ffffff',
            padding: "100px"
          },
            evenContent: {
                padding: "100px"
          },
          heroImage: {
            paddingTop: '150px',
            paddingBottom: '150px',
            position: 'absolute',
            height: '100%'
          },
          Text1: {
            paddingTop: '150px',
            position: 'relative',
            textAlign: 'center',
            color: '#d77474',
            fontSize: '14px'
          },
          heroText2: {
            position: 'relative',
            textAlign: 'left',
            color: '#000000',
            fontSize: '45px'
          },
          heroText3: {
            position: 'relative',
            textAlign: 'left',
            color: '#6c6c6c',
            fontSize: '16px'
          },
          buttonHero: {
            marginLeft:'auto',
            marginRight:'auto',
            borderStyle: 'none',
            borderColor: '#000000',
            backgroundColor: '#ac1313',
            padding: '20px 40px',
            color: '#ffffff',
            lineHeight: '22px'
          },
          buttonHeroContainer: {
            marginTop: '20px',
            paddingBottom: '150px',
          },
          activitiesImages: {
            width: 256,
            height: 256,
            marginRight: 20
          },
          head: {
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#000000'
          },
          buttonContainer:{
            display: "flex"
          },
          buttonMore: {
            marginLeft:'auto',
            marginRight:'auto'
          },
          topicDevider: {
            marginTop: 50,
          },
          contentDevider: {
            marginTop: 20,
          },
          contactHead: {
            fontWeight: "bold",
            textAlign: 'center'
          },
          contactDetail: {
            fontWeight: "normal",
            textAlign: 'center',
            color: '#6c6c6c'
          },
          dividedImg: {
              width: '100%'
          }
      };
    return (
        <>
            {/* Content 1 */}
            <img style={styles.bgImage} src={bgImg} alt="bg"/>
            <div style={styles.evenContent}>
                <Container style={styles.topicDevider} fluid='md'>
                    <Row>
                        <Col>
                            <h1 style={styles.heroText2}>{myString.contact_us_txt}</h1>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Content 2 */}
            <div style={styles.oddContent}>
                <Container style={styles.topicDevider}>
                <h3 style={styles.Text1}>{myString.contact_us_upper_txt}</h3>
                <h2 style={styles.head}>{myString.get_in_touch_txt}</h2>
                <div style={styles.topicDevider} />
                <Row>
                    <Col>
                        <div>
                            <p style={styles.contactHead}>{myString.phone_txt}</p>
                            <p style={styles.contactDetail}>{myString.telephone1}<br />{myString.telephone2}</p>
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <p style={styles.contactHead}>{myString.location_txt}</p>
                            <a style={styles.contactDetail} href="https://goo.gl/maps/8EFMu3RJuSC3BaGs9" target="_blank" rel="noopener noreferrer"><p>{myString.location}</p></a>
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <p style={styles.contactHead}>{myString.email_txt}</p>
                            <p style={styles.contactDetail}>{myString.email1}<br />{myString.email2}</p>
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <p style={styles.contactHead}>{myString.facebook_txt}</p>
                            <a style={styles.contactDetail} href="https://www.facebook.com/EasyWayTH" target="_blank" rel="noopener noreferrer"><p style={styles.contactDetail}>{myString.facebook}</p></a>
                        </div>
                    </Col>
                </Row>
                <div style={styles.topicDevider} />

                <img style={styles.dividedImg} src={bgImg} alt="divided" />
                <div style={styles.topicDevider} />

                <h2 style={styles.head}>{myString.office_hour_txt}</h2>
                <Row>
                    <Col>
                        <p style={styles.contactDetail}>{myString.open_date}</p>
                        <p style={styles.contactDetail}>{myString.open_time}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p style={styles.contactDetail}>{myString.working_timezone1}<br />{myString.working_timezone2}</p>
                    </Col>
                </Row>
                {/* <div style={styles.topicDevider} /> */}
                {/* <img style={styles.dividedImg} src={mapImg} alt="map" /> */}


                </Container>
            </div>
        </>
    )
}
export default ContactUs;