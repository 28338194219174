const myString = {
    "contact_us_txt": 'Contact Us',
    "contact_us_upper_txt": 'CONTACT US',
    "get_in_touch_txt": "Let's get in touch",

    "phone_txt": 'PHONE',
    "telephone1": '(+66)8-9653-3570',
    "telephone2": '(+66)8-6695-3904',

    "location_txt": "LOCATION",
    "location": "16/1 Soi 1 Talayluang Road, Muang Songkhla, Songkhla 90000 Thailand",

    "email_txt": "EMAIL",
    "email1": "arunwan.por@gmail.com",
    "email2": "tanachai592010@yahoo.com",

    "facebook_txt": "FACEBOOK",
    "facebook": "https://www.facebook.com/EasyWayTH",

    "office_hour_txt": "Office Hours",
    "open_date": "every day",
    "open_time": "8:00 A.M. - 5 P.M.",
    "working_timezone1": "Current local time in Bangkok, Thailand",
    "working_timezone2": "Time Zone: UTC+7",

}
export default myString