import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import myString from '../res/staffVacancies.js';
import bgImg from '../res/images/temp1.jpg';

function StaffVacancies() {
    const styles = {
      bgImage: {
        position: 'absolute',
        zIndex: -1,
        width: '100%',
        height: '100%',
        resizeMode: 'contain'
      },
      oddContent: {
          backgroundColor: '#ffffff',
          padding: "100px"
      },
      evenContent: {
          padding: "100px"
      },
      founderImg: {
        position: 'absolute',
        height: '100%'
      },
      heroText2: {
        position: 'relative',
        textAlign: 'left',
        color: '#000000',
        fontSize: '45px'
      },
      heroText3: {
        position: 'relative',
        textAlign: 'left',
        color: '#6c6c6c',
        fontSize: '16px'
      },
      heroText3Bold: {
        position: 'relative',
        textAlign: 'left',
        color: '#6c6c6c',
        fontSize: '16px',
        fontWeight: 'bold'
      },
      topicDevider: {
        marginTop: 50,
      },
      vacancyText: {
        marginTop: 50,
        marginLeft: '5%',
        marginRight: '5%',
      },
    };
    return (
        <>
          {/* Content 1 */}
          <img style={styles.bgImage} src={bgImg} alt="bg" />
          <div style={styles.evenContent}>
              <Container style={styles.topicDevider}>
                  <Row>
                  <Col>
                      <h1 style={styles.heroText2}>{myString.staff_vacancies_txt}</h1>
                      {/* <p style={styles.heroText3}>{myString.staff_vacancies_detail_1}<br />
                      {myString.staff_vacancies_detail_email}</p>
                      <p style={styles.heroText3}>{myString.staff_vacancies_detail_2}</p> */}
                  </Col>
                  <Col>

                  </Col>
                  </Row>
              </Container>
          </div>
          <div style={styles.oddContent}>
            <div style={styles.vacancyText}>
              <span style={styles.heroText3Bold}>{myString.staff_vacancies_detail_1_bold}</span>
              <span style={styles.heroText3}>{myString.staff_vacancies_detail_1}<br />
              {myString.staff_vacancies_detail_email}</span>
              <p style={styles.heroText3}>{myString.staff_vacancies_detail_2}</p>
            </div>
          </div>
        </>
    )
}
export default StaffVacancies;