import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
// import NavDropdown from 'react-bootstrap/NavDropdown'
// import {Form, Table} from 'react-bootstrap'
import history from './history'

function Menubar() {
    return (
        <Navbar collapseOnSelect expand="lg" bg="danger" variant="dark">
            <Navbar.Brand onClick={() => history.push('/')}>Asian Teach</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link onClick={() => history.push('/')}>HOME</Nav.Link>
                    <Nav.Link onClick={() => history.push('/about-school')}>ABOUT SCHOOL</Nav.Link>
                    <Nav.Link onClick={() => history.push('/our-courses')}>OUR COURSES</Nav.Link>
                    <Nav.Link onClick={() => history.push('/staff-vacancies')}>STAFF VACANCIES</Nav.Link>
                    <Nav.Link onClick={() => history.push('/contact-us')}>CONTACT US</Nav.Link>
                    {/* <NavDropdown title="EXPERIENCES" id="collasible-nav-dropdown"> */}
                        {/* <Form inline> */}
                            {/* <Table> */}
                                {/* <tbody> */}
                                {/* <td> */}
                                    {/* <tr><NavDropdown.Item onClick={() => history.push('/teach')}>TEACH</NavDropdown.Item></tr> */}
                                    {/* <NavDropdown.Divider /> */}
                                    {/* <tr><NavDropdown.Item onClick={() => history.push('/teach')}>TEACH IN THAILAND</NavDropdown.Item></tr> */}
                                    {/* <tr><NavDropdown.Item onClick={() => history.push('/teach')}>CAMP COUNSELOR THAILAND</NavDropdown.Item></tr> */}
                                {/* </td> */}
                                {/* </tbody> */}
                            {/* </Table> */}
                        {/* </Form> */}
                        {/* <tr><NavDropdown.Item onClick={() => history.push('/teach')}>TEACH</NavDropdown.Item></tr> */}
                    {/* </NavDropdown> */}
                    {/* <NavDropdown title="EDUCATION" id="collasible-nav-dropdown"> */}
                        {/* <NavDropdown.Item onClick={() => history.push('/education')}>ONLINE TEFL/TESOL TRAINING AND CERTIFICATION</NavDropdown.Item> */}
                        {/* <NavDropdown.Item href="#action/3.2">OVERSEAS IN-CLASS TESOL CERTIFICATION</NavDropdown.Item> */}
                        {/* <NavDropdown.Item href="#action/3.3">CULTURAL ORIENTATION COURSES</NavDropdown.Item> */}
                    {/* </NavDropdown> */}
                    {/* <NavDropdown title="BLOG" id="collasible-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">NEWS</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">LIVING IN THAILAND</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">TEACHER STORY</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">INTERNATIONAL INTERNSHIP</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">MAKING A DIFERENCE</NavDropdown.Item>
                    </NavDropdown> */}
                    {/* <NavDropdown title="ABOUT US" id="collasible-nav-dropdown"> */}
                        {/* <NavDropdown.Item onClick={() => history.push('/policy')}>PRIVACY POLICY</NavDropdown.Item> */}
                        {/* <NavDropdown.Item onClick={() => history.push('/about-us')}>ABOUT US</NavDropdown.Item> */}
                        {/* <NavDropdown.Item href="#action/3.3">ACCREDITATION</NavDropdown.Item> */}
                        {/* <NavDropdown.Item href="#action/3.3">LOCATIONS</NavDropdown.Item> */}
                        {/* <NavDropdown.Item href="#action/3.3">CUSTOMER TESTIMONIALS</NavDropdown.Item> */}
                        {/* <NavDropdown.Item href="#action/3.3">TEACHER STORIES</NavDropdown.Item> */}
                    {/* </NavDropdown> */}
                </Nav>


                {/* <Nav>
                    <Nav.Link href="#deets">More deets</Nav.Link>
                    <Nav.Link eventKey={2} href="#memes">Dank memes</Nav.Link>
                </Nav> */}
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Menubar;