const myString = {
    "heroText1": 'EASY WAY LANGUAGE SCHOOL',


    "heroText2": 'The Best Language School in Songkhla, Thailand',


    "heroText3": 'English, Thai, and Japanese Language Classes.',


    "buttonHero": 'GET IN TOUCH',


    "content_Head": 'Why Choose Easy Way Language School?',

    
    "content_detail1_head": 'Professional Class',
    "content_detail1": 'Classes are available to all age of learners and taught by professional teachers',
    "content_detail2_head": 'Dynamic Classroom',
    "content_detail2": 'Classroom is dynamic, learner can choose between an individual class or study in group. Time and channel of study can be choosen.',    
}
export default myString