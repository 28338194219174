import React, {Component} from 'react';
import history from './history';
import Home from '../Home';
import AboutSchool from '../about-school/AboutSchool';
import Ourcourses from '../our-courses/OurCourses';
import ContactUs from '../aboutUs/ContactUs';
import StaffVacancies from '../aboutUs/StaffVacancies';
import { Router, Switch, Route } from 'react-router-dom';

class Routes extends Component {
    componentDidMount() {
        document.title = "Easy Way"; 
    }
    render(){
        return(
            <Router history={history}>
                <Switch>
                    {/* HOME */}
                    <Route path="/" exact component={Home} />

                    {/* TEACH */}
                    <Route path="/about-school" exact component={AboutSchool} />

                    {/* EDUCATION */}
                    <Route path="/our-courses" exact component={Ourcourses} />
                    
                    {/* ABOUT US */}
                    <Route path="/staff-vacancies" exact component={StaffVacancies} />
                    <Route path="/contact-us" exact component={ContactUs} />
                </Switch>
            </Router>
        )
    }
}

export default Routes;