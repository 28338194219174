import React from 'react';

var style = {
    backgroundColor: "#F8F8F8",
    borderTop: "1px solid #E7E7E7",
    textAlign: "left",
    padding: "0",
    position: "absoluted",
    left: "0",
    bottom: "0",
    height: "auto",
    width: "100%",
}

var phantom = {
    display: 'block',
    padding: '20px',
    height: '60px',
    width: '100%',
}

function Footer() {
    const styles = {
        textFoot: {
            position: 'relative',
            color: '#6c6c6c',
            fontSize: '16px',
            textAlign: 'center',
            padding: '20px'
        }
    };
    return (
        <>
            <div style={phantom} />
            <div style={style}>
                {/* <p style={styles.textFoot}>การเข้าถึงวัฒนธรรม สามารถเริ่มต้นด้วยการเรียนภาษา</p> */}
                <p style={styles.textFoot}>To get into other cultural, this can be started with the knowledge of languages</p>
            </div>
        </>
    )
}

export default Footer;